import { createContext, Dispatch, Reducer, useContext, useMemo, useReducer } from 'react';

import {
  DiagnosisTreatmentAction,
  DiagnosisTreatmentProviderProps,
  DiagnosisTreatmentState,
} from './DiagnosisTreatmentContext.types';

const defaultDiagnosisTreatmentState: DiagnosisTreatmentState = {
  loading: true,
  patientIntro: '',
  diagnosis: '',
  background: '',
  treatment: '',
  nextSteps: '',
};

const DiagnosisTreatmentContext = createContext<
  | {
      state: DiagnosisTreatmentState;
      dispatch: Dispatch<DiagnosisTreatmentAction>;
    }
  | undefined
>(undefined);

const DiagnosisTreatmentReducer: Reducer<DiagnosisTreatmentState, DiagnosisTreatmentAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SET_FIELDS':
      return { ...state, ...payload };
    case 'CLEAR':
      return defaultDiagnosisTreatmentState;
    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const DiagnosisTreatmentProvider = ({ children }: DiagnosisTreatmentProviderProps) => {
  //const [state, dispatch] = useReducer(DiagnosisTreatmentReducer, defaultDiagnosisTreatmentState);
  const [state, dispatch] = useReducer<Reducer<DiagnosisTreatmentState, DiagnosisTreatmentAction>>(
    DiagnosisTreatmentReducer,
    defaultDiagnosisTreatmentState
  );
  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <DiagnosisTreatmentContext.Provider value={contextValue}>{children}</DiagnosisTreatmentContext.Provider>;
};

export const useDiagnosisTreatment = () => {
  const context = useContext(DiagnosisTreatmentContext);
  if (!context) {
    throw new Error('useDiagnosisTreatment must be used within a DiagnosisTreatmentProvider');
  }
  return context;
};
