import { gql } from '@apollo/client';

export const GENERATE_DIAGNOSIS_TREATMENT = gql`
  mutation generateConsultationDiagnosisTreatment($consultationId: ID!, $diagnosisTreatment: String!) {
    generateConsultationDiagnosisTreatment(consultation_id: $consultationId, diagnosis_treatment: $diagnosisTreatment) {
      id
      consultation_id
      diagnosis
      next_steps
      thread_id
      treatment
      background
    }
  }
`;

export const SEND_DIAGNOSIS_TREATMENT = gql`
  mutation sendConsultationDiagnosisTreatment($input: SendConsultationDiagnosisTreatmentInput!) {
    sendConsultationDiagnosisTreatment(input: $input) {
      id
      body {
        ... on TextMessage {
          id
          content
        }
      }
      created_at
      deleted_at
      body_type
      sender {
        id
        last_name
        first_name
        avatar
      }
    }
  }
`;
