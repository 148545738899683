import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { UserContext } from '../../../common/authentication/UserContext';
import { User } from '../../../common/authentication/types';
import DermasenceLogo from '../../../common/pharma-brands/DermasenceLogo';
import NotAuthenticatedShell from '../../shell/NotAuthenticatedShell';
import { Header, LogInForm } from '../components';

export function LogInPage() {
  const { t } = useTranslation();
  const { setAuthenticatedUser } = useContext(UserContext);

  function onSuccessfulLogIn(user: User): void {
    setAuthenticatedUser(user);
  }

  return (
    <NotAuthenticatedShell>
      <Header>{t('authentication:login_to_your_account')}</Header>
      <p className="mt-2 text-center text-sm leading-5 text-gray-600 max-w">
        <Link
          to="/doctor/signup"
          className="font-medium text-teal-600 hover:text-teal-500 focus:outline-none focus:underline transition ease-in-out duration-150"
        >
          {t('authentication:no_account')}
        </Link>
      </p>

      <div className="mt-10">
        <LogInForm onSuccessfulLogIn={onSuccessfulLogIn} />
        <div className="flex justify-center">
          <DermasenceLogo />
        </div>
      </div>
    </NotAuthenticatedShell>
  );
}
