import { useRef } from 'react';
import ReactQuill from 'react-quill';

import { TinyRichTextEditorProps } from './TinyRichTextEditor.props';

const reactQuillModules = {
  toolbar: [['bold', 'italic', 'underline'], [{ list: 'ordered' }, { list: 'bullet' }], ['link']],
};
const reactQuillFormats = ['bold', 'italic', 'underline', 'list', 'bullet', 'link'];

export const TinyRichTextEditor = (props: TinyRichTextEditorProps) => {
  const { label, name, onChangeCallback, value } = props;
  const tinyEditorRef = useRef(null);

  return (
    <div>
      <span>{label}</span>
      <ReactQuill
        ref={tinyEditorRef}
        id={`tiny_rich_text_editor_${name}`}
        formats={reactQuillFormats}
        modules={reactQuillModules}
        onChange={(changedValue) => {
          onChangeCallback(name, changedValue);
        }}
        theme="snow"
        value={value}
      />
    </div>
  );
};
