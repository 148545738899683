import { SparklesIcon } from '@heroicons/react/outline';
import { ArrowUpIcon } from '@heroicons/react/solid';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CreateDiagnosisTreatmentProps } from './CreateDiagnosisTreatment.types';
import { DiagnosisTreatmentProvider } from '../../../../common/context/DiagnosisTreatmentContext';
import { Button } from '../../../../common/form/components';
import { Message } from '../../../graphql/types';
import DiagnosisTreatmentModal from '../DiagnosisTreatmentModal';

export const CreateDiagnosisTreatment = (props: CreateDiagnosisTreatmentProps) => {
  const { consultationId, createMessageCallback, creator } = props;
  const { t } = useTranslation();
  const [diagnosisTreatment, setDiagnosisTreatment] = useState<string>('');
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDiagnosisTreatment(event.target.value);
  };

  const getDefaultPatientIntro = () =>
    t('doctor:consultation:diagnosis_treatment:modal:default_patient_intro', {
      patientFirstName: creator.first_name,
      lng: creator.language,
    });

  return (
    <div>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setModalVisibility(true);
        }}
      >
        <div className="flex items-center p-3 border rounded-md bg-gray-100">
          <label className="flex items-center space-x-2 mr-4" htmlFor="diagnosisTreatment">
            <SparklesIcon className="text-black h-4 w-4" />
            <span className="font-semibold text-black">
              {t('doctor:consultation:diagnosis_treatment:create_form:label')}
            </span>
          </label>
          <input
            className="appearance-none flex-1 p-2 border focus:outline-none border-gray-300 rounded-md focus:ring-teal-500 focus:border-teal-500"
            required
            name="diagnosisTreatment"
            value={diagnosisTreatment}
            onChange={onChangeHandler}
          />
          <Button className="ml-4" label="" icon={<ArrowUpIcon className="w-4 h-4" />} type="submit" />
        </div>
      </form>
      <DiagnosisTreatmentProvider>
        <DiagnosisTreatmentModal
          consultationId={consultationId}
          createMessageCallback={(message: Message) => {
            setDiagnosisTreatment('');
            createMessageCallback(message);
          }}
          diagnosisTreatment={diagnosisTreatment}
          defaultPatientIntro={getDefaultPatientIntro()}
          setVisibility={setModalVisibility}
          visibility={modalVisibility}
        />
      </DiagnosisTreatmentProvider>
    </div>
  );
};
